.container {
    margin-top: 20px;
}

.drag-item {
    border: 2px solid black;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    margin: 0 5px 10px;
}

.handle-item {
    font-size: 1.5rem;
    background: #0275d8;
}

.tweet-item {
    background: #5bc0de;
}

.drag-item p {
    margin: 0;
}

.matched {
    flex-direction: column;
    background: #5cb85c;
}